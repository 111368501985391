input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
}

input[type='range'] {
    width: 20em;
    height: 1em;
    background: var(--bg-secondary);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .1s;
    transition: opacity .1s, width .5s;
    border-radius: 1em;
}

input[type='range']::-moz-range-thumb {
    width: 1em;
    height: 1.5em;
    border: 3px var(--bg-secondary) solid;
    background: var(--bg-primary);
    cursor: pointer;
    transition: transform 50ms ease-in-out;
}

input[type='range']::-webkit-slider-thumb {
    width: 1.5em;
    height: 1.9em;
    border: 3px var(--bg-secondary) solid;
    border-radius: 0.5em;
    background: var(--bg-primary);
    cursor: pointer;
    transition: transform 50ms ease-in-out;
}

.time-date-container,
.date-display-container,
.time-display-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--bg-secondary);
}

.time-date-content {
    display: flex;
    gap: 3em;
}

.time-display,
.date-display {
    margin: 0.2em;
    font-size: 2.2rem;
    font-weight: 700;
    transition: font-size .5s;
}

.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type='range']:disabled {
    opacity: 20%;
    pointer-events: none;
}

.mode-buttons {
    display: flex;
    gap: 1em;
    margin-top: 2em;
    margin-bottom: 1.3em;
    white-space: nowrap;
}

.mode-buttons>button {
    font-size: 1.3rem;
}

.all-day-button>span>svg {
    color: rgb(199, 119, 0);
}

.all-year-button>span>svg {
    color: rgb(126, 34, 1);
}

.disable-time-button>span>svg {
    color: rgb(27, 156, 91);
}

.disable-time-button,
.all-day-button,
.all-year-button {
    margin: 0;
    font-weight: 600;
    color: var(--bg-secondary);
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 1em;
    transition: transform 50ms ease-in-out, font-size .5s;
}

.all-year-button,
.all-day-button {
    width: 6.5em;
}

.disable-time-button {
    width: 9em;
}

.active {
    background-color: var(--bg-secondary);
    color: var(--bg-primary);
}

.time-date-container,
.mode-buttons {
    animation: fadeIn .3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media not all and (hover: none) {
    input[type='range']:hover {
        opacity: 1;
    }


    input[type='range']:hover::-webkit-slider-thumb {
        transform: scale(1.2);
        transform-origin: center;
    }

    input[type='range']:hover::-moz-range-thumb {
        transform: scale(1.2);
        transform-origin: center;
    }

    .disable-time-button:hover,
    .all-day-button:hover,
    .all-year-button:hover {
        background-color: var(--bg-secondary);
        color: var(--bg-primary);
        transform: scale(1.1);
        transform-origin: center;
    }
}

@media screen and (max-width: 584px) {
    .time-date-container {
        width: 0em;
    }

    .time-display,
    .date-display {
        margin: 0.2em;
        font-size: 2rem;
        font-weight: 700;
    }

    input[type='range'] {
        width: 15em;
    }

    .mode-buttons>button {
        font-size: 1rem;
    }

    .mode-buttons {
        margin: 1.2em;
    }
}

@media screen and (max-width: 450px) {

    .time-display,
    .date-display {
        margin: 0.2em;
        font-size: 1.6rem;
        font-weight: 700;
    }

    input[type='range'] {
        width: 13em;
        height: 0.8em;
    }

    .mode-buttons {
        margin: 1.2em;
    }

    .time-date-content {
        gap: 1em;
    }
}

@media screen and (max-width: 404px) {

    .time-display,
    .date-display {
        margin: 0.2em;
        font-size: 1.6rem;
        font-weight: 700;
    }

    input[type='range'] {
        width: 11em;
        height: 0.8em;
    }

    input[type='range']::-moz-range-thumb {
        height: 1.1em;
        width: 0.8em;
    }

    input[type='range']::-webkit-slider-thumb {
        height: 1.6em;
        width: 1.3em;
    }

    .mode-buttons>button {
        font-size: 0.8rem;
    }

    .mode-buttons {
        margin: 1.2em;
    }

    .time-date-content {
        gap: 1em;
    }
}

@media screen and (max-width: 320px) {
    .mode-buttons>button {
        font-size: 0.7rem;
    }

    input[type='range'] {
        width: 8em;
    }

    .time-display,
    .date-display {
        font-size: 1rem;
        margin-top: 1em;
    }
}