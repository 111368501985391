.active-modal {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  background: var(--bg-primary);
  border-radius: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

::-webkit-scrollbar-thumb {
  background: var(--bg-secondary);
  border-radius: 1em;
}

html {
  background-color: var(--bg-primary);
  overflow-x: hidden;
  font-family: 'Nunito', 'Varela Round', 'sans-serif';
  scrollbar-color: var(--bg-secondary) var(--bg-primary);
  transition: background-color .2s;
}

/* for dark mode */
.border {
  filter: drop-shadow(0em 0.1em var(--bg-secondary));
}

/*---*/

.top-buttons-container {
  display: flex;
  gap: 0.1em;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.1em;
}

.all-critters-heading-container {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--bg-secondary);
}

.all-critters-heading {
  margin-bottom: 0;
  margin-top: 1em;
}

.all-current-buttons-container {
  display: flex;
  gap: 1.5em;
  margin-top: 1em;
  transition: margin .5s, font-size .5s;
}

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.caught-total-content,
.caught-total-heading,
.all-critters-heading-container {
  animation: bounceIn .1s ease-out;
  animation-fill-mode: forwards;
}

@keyframes bounceIn {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1.1);
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hemisphere-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1em;
  font-size: 1.3rem;
  animation: fadeIn .3s;
  color: var(--bg-secondary);
  transition: font-size .5s;
}

.southern-hemisphere-button,
.northern-hemisphere-button {
  cursor: pointer;
  border: none;
  border-radius: 2em;
  width: 6em;
  font-size: 1.3rem;
  background: none;
  font-weight: 600;
  color: var(--bg-secondary);
  transition: transform 50ms ease-in-out, font-size .5s;
}

.hemisphere-buttons>h4 {
  margin: 0;
  font-weight: 800;
  font-size: 1.3rem;
  transition: font-size .5s;
}

.critter-button:hover .critter-button-icon {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 2px) rotate(2deg);
    transform: translate(2px, 2px) rotate(2deg);
  }
}

.active {
  background-color: var(--bg-secondary);
  color: var(--bg-primary);
}

.mid-buttons-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  width: 90vw;
  margin-top: 1em;
}

.mid-line {
  background: rgb(201, 186, 162);
  height: 0.1em;
  width: 100vw;
  margin-bottom: 0.5em;
}

.critter-buttons-container {
  display: flex;
  justify-content: flex-start;
}

.critter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(201, 186, 162);
  border-radius: 1em 1em 0 0;
  transition: background-color .2s, width .5s;
}

.critter-button-icon {
  width: 4em;
  height: auto;
  transition: width .5s;
}

.critter-display-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.critter-display-content {
  display: flex;
  justify-content: center;
  width: 100vw;
  overflow-x: auto;
  transition: height .5s, margin .5s;
}

.critter-active {
  background-color: rgb(255, 183, 0);
}

.clear-mark-buttons-container {
  transition: margin .5s;
}

.caught-buttons-container,
.clear-mark-buttons-container {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
  width: max-content;
  gap: 1em;
  height: 2em;
  margin-top: 1.8em;
  margin-bottom: 0.5em;
}

.caught-mode-button,
.caught-mode-done-button,
.toggle-caught-button,
.mark-all-caught,
.clear-all-caught {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  width: max-content;
  padding-left: 1em;
  padding-right: 1em;
  background: none;
  border: 1px var(--bg-secondary) solid;
  color: var(--bg-secondary);
  border-radius: 1em;
}

.caught-mode-button,
.toggle-caught-button,
.mark-all-caught,
.clear-all-caught {
  transition: transform 50ms ease-in-out, font-size .5s;
}

.caught-mode-done-button {
  margin-left: 0.2em;
  margin-right: 7em;
  height: max-content;
  transition: margin .5s, transform 50ms ease-in-out, font-size .5s;
}

.view-all-button,
.view-current-button {
  cursor: pointer;
  color: var(--bg-secondary);
  background: none;
  border: none;
  border-radius: 1em;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5em;
  width: max-content;
  padding-left: 1em;
  padding-right: 1em;
  transition: transform 50ms ease-in-out;
}

.caught .critter-entry-icon {
  opacity: 20%;
}

.caught-total-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.9em;
  margin-bottom: 1.2em;
  color: var(--bg-secondary);
}

.caught-total-heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.5em;
}

.caught-total-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  font-size: 2rem;
  font-weight: 600;
  transition: font-size .5s;
}

.critter-total-icon {
  cursor: pointer;
  width: 3em;
  background-color: rgba(148, 104, 104, 0.607);
  border-radius: 1em;
  transition: transform 50ms ease-in-out;
}

.critter-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4em;
}

.critter-entry {
  aspect-ratio: 1/1;
  cursor: pointer;
  padding: 0.5em;
  cursor: pointer;
  z-index: 2;
}

.critter-entry-icon {
  width: 4em;
  height: 4em;
  transition: transform 80ms ease-in-out, width .5s, height .5s;
}

.active-critter-display {
  border-top: 0.1em solid rgb(201, 186, 162);
  border-left: 0.1em solid rgb(201, 186, 162);
}

.entry-border {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 0.1em solid rgb(201, 186, 162);
  border-right: 0.1em solid rgb(201, 186, 162);
}

.tooltip {
  pointer-events: none;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 3em;
  width: 6em;
  transition: transform 80ms ease-in-out;
}

.tooltip-text {
  visibility: hidden;
  font-size: 0.7rem;
  font-weight: 600;
  width: max-content;
  padding: 0.5em;
  border-radius: 1em;
  color: var(--bg-primary);
  background-color: var(--bg-secondary);
  white-space: nowrap;
  z-index: 5;
  transition: transform 80ms ease-in-out;
}

.unavailable {
  visibility: hidden;
  pointer-events: none;
}

h4,
p {
  margin: 0;
  margin-bottom: 0.5em;
}

.info-card-button,
.help-card-button,
.theme-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: var(--bg-secondary);
}

.info-card-button,
.help-card-button {
  background: none;
  font-size: 2rem;
  transition: font-size .5s;
}

.theme-button {
  border-radius: 3em;
  font-size: 1rem;
  padding: 0.5em;
  padding-right: 0.6em;
  padding-left: 0.6em;
  transition: font-size .5s;
  margin-left: 2em;
}

@media not all and (hover: none) {
  button:hover {
    background: var(--bg-secondary);
    color: var(--bg-primary);
    font-weight: 700;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transform-origin: center;
  }

  .info-card-button:hover,
  .help-card-button:hover {
    background: none;
    color: var(--bg-secondary);
  }

  .critter-total-icon:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transform-origin: center;
  }

  .critter-button:hover {
    background: rgb(255, 183, 0);
  }

  .entry-border:hover .critter-entry-icon {
    cursor: pointer;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transform-origin: center;
  }

  .entry-border:hover .tooltip-text {
    visibility: visible;
    cursor: pointer;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transform-origin: center;
  }

  .entry-border:hover .unavailable {
    visibility: hidden;
  }
}

@media screen and (max-width: 1500px) {
  .caught-mode-done-button {
    margin-right: 0;
  }

  .clear-mark-buttons-container {
    margin-right: 7em;
  }
}

/* Grid sizes on screen width changes */
@media screen and (max-width: 1313px) {
  .critter-entry-icon {
    width: 3.5em;
    height: 3.5em;
  }

  .tooltip-text {
    font-size: 0.8rem;
  }

  .tooltip {
    margin-bottom: 2.9em;
  }
}

@media screen and (max-width: 1169px) {
  .critter-entry-icon {
    width: 3em;
    height: 3em;
  }

  .tooltip-text {
    font-size: 0.4rem;
  }

  .tooltip {
    margin-bottom: 2.5em;
  }
}

@media screen and (max-width: 1041px) {
  .default-disp-content {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 521px) {
  .sea-disp-content {
    display: flex;
    justify-content: flex-start;
  }
}

/*------------------------------*/

@media screen and (max-width: 584px) {

  .info-card-button,
  .help-card-button {
    font-size: 1.3rem;
  }

  .theme-button {
    font-size: 0.8rem;
    padding: 0.3em;
    padding-left: 0.4em;
    padding-right: 0.4em;
  }

  .hemisphere-buttons,
  .hemisphere-buttons>h4,
  .southern-hemisphere-button,
  .northern-hemisphere-button {
    font-size: 1rem;
  }

  .critter-button-icon {
    width: 2em;
  }

  .critter-button {
    margin-top: 1.5em;
    width: 3em;
  }

  .caught-mode-button,
  .caught-mode-done-button,
  .toggle-caught-button,
  .mark-all-caught,
  .clear-all-caught {
    font-size: 0.8rem;
    height: min-content;
    white-space: nowrap;
  }

  .caught-buttons-container {
    margin-top: 1.3em;
  }

  .clear-mark-buttons-container {
    margin-right: 5em;
    gap: 0.5em;
  }

  .caught-buttons-container,
  .clear-mark-buttons-container {
    margin-bottom: 0.2em;
  }

  .caught-total-content {
    font-size: 1.6rem;
  }

  .mid-buttons-container {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {

  .caught-mode-button,
  .caught-mode-done-button,
  .toggle-caught-button,
  .mark-all-caught,
  .clear-all-caught {
    font-size: 0.6rem;
    height: min-content;
    white-space: nowrap;
  }

  .caught-buttons-container {
    margin-top: 0.9em;
    gap: 0.5em;
  }

  .clear-mark-buttons-container {
    margin-right: 3em;
  }

  .caught-total-content {
    font-size: 1.3rem;
  }

  .all-current-buttons-container {
    margin-bottom: 0.8em;
  }

  .critter-button {
    width: 2.5em;
  }

  .all-current-buttons-container>button {
    margin: 0;
  }
}

@media screen and (max-width: 404px) {

  .hemisphere-buttons,
  .hemisphere-buttons>h4,
  .southern-hemisphere-button,
  .northern-hemisphere-button {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 364px) {
  .clear-mark-buttons-container {
    margin-right: 0;
  }
}

@media screen and (max-width: 320px) {

  .hemisphere-buttons,
  .hemisphere-buttons>h4,
  .southern-hemisphere-button,
  .northern-hemisphere-button {
    font-size: 0.7rem;
  }

  .caught-mode-button,
  .caught-mode-done-button,
  .toggle-caught-button,
  .mark-all-caught,
  .clear-all-caught {
    font-size: 0.5rem;
    height: min-content;
    white-space: nowrap;
  }

  .view-all-button,
  .view-current-button {
    font-size: 0.8rem;
  }

  .caught-buttons-container {
    gap: 0.1em;
  }

  .clear-mark-buttons-container {
    gap: 0.1em;
  }
}